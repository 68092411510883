<template>
  <v-container fluid class="main-container">
    <v-row class="no-gutters">
      <v-col class="mr-2" v-show="showSideContainers">
        <div class="left-column">
                <v-card elevation="2" style="height: 100%">
                  <v-tabs v-model="tab" class="table_tabs_vp" height="25">
                    <v-tab>Inference</v-tab>
                    <v-tab>Animals</v-tab>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-container fluid class="container-vp">
                          <v-card-title class="justify-start inference-title">
                            Calving
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_contraction"
                              v-model="show_contraction"
                            >
                              <template #label>
                                <span>Tail Up</span>
                                <span
                                  class="d-inline-block"
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: rgb(60, 102, 104);
                                    margin-left: 40px;
                                    border-radius: 50%;
                                  "
                                ></span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Estrus
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_estrus"
                              v-model="show_estrus"
                            >
                              <template #label>
                                <span>Heat</span>
                                <span
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: #a0215f;
                                    margin-left: 54px;
                                    border-radius: 50%;
                                  "
                                >
                                </span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_estrus"
                            v-model="show_estrus"
                          >
                            <template #label>
                              <span>Chin Rest</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(116, 165, 169);
                                  margin-left: 20px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_estrus"
                            v-model="show_estrus"
                          >
                            <template #label>
                              <span>Mounting</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(141, 82, 50);
                                  margin-left: 20px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Traits
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_activity"
                              v-model="show_activity"
                            >
                              <template #label>
                                <span>Activity</span>
                                <span
                                  class="d-inline-block"
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: rgb(251, 172, 45);
                                    margin-left: 35px;
                                    border-radius: 50%;
                                  "
                                ></span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_grazing"
                            v-model="show_grazing"
                          >
                            <template #label>
                              <span>Grazing</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(6, 104, 39);
                                  margin-left: 33px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch
                            :disabled="!has_gender"
                            v-model="show_gender"
                          >
                            <template #label>
                              <span>Gender</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: purple;
                                  margin-left: 36px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container
                          fluid
                          class="toggle-switches-events container-vp"
                        >
                          <v-switch :disabled="!has_age" v-model="show_age">
                            <template #label>
                              <span>Age</span>
                              <span
                                class="d-inline-block"
                                style="
                                  display: inline-block;
                                  width: 10px;
                                  height: 10px;
                                  background-color: rgb(60, 102, 104);
                                  margin-left: 60px;
                                  border-radius: 50%;
                                "
                              ></span>
                            </template>
                          </v-switch>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Hunters
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch
                              :disabled="!has_predator"
                              v-model="show_predator"
                            >
                              <template #label>
                                <span>Predators</span>
                                <span
                                  style="
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    background-color: rgb(136, 53, 40);
                                    margin-left: 19px;
                                    border-radius: 50%;
                                  "
                                >
                                </span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                        <v-container fluid class="container-vp label-container">
                          <v-card-title class="justify-start inference-title">
                            Thermal
                          </v-card-title>
                          <v-container
                            fluid
                            class="toggle-switches-events container-vp"
                          >
                            <v-switch disabled>
                              <template #label>
                                <span>Heat Maps</span>
                              </template>
                            </v-switch>
                          </v-container>
                        </v-container>
                      </v-tab-item>
                      <v-tab-item>
                        <div v-if="!animals.length">
                          <div style="margin-top: 85%; margin-left: 10%">
                            <v-img
                              width="150px"
                              height="150px"
                              src="../assets/BETSY_wave_smile.png"
                              contain
                            ></v-img>
                          </div>
                          <v-spacer></v-spacer>
                          <div
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: #0090a4;
                              text-align: center;
                            "
                          >
                            ANIMAL
                          </div>
                          <div
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: #0090a4;
                              text-align: center;
                            "
                          >
                            IDENTIFICATION
                          </div>
                          <div
                            style="
                              font-size: 14px;
                              letter-spacing: 2px;
                              color: #0090a4;
                              text-align: center;
                            "
                          >
                            PENDING
                          </div>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-card>
              </div>
        <!-- <div
          class="left-column"
          :class="{
            'custom-style': animals.length > 0 && !displayingOneAnimal,
          }"
          v-if="animals.length > 0 && !displayingOneAnimal"
        >
          <v-card
            v-for="(animal, index) in animals"
            :key="index"
            @click="displayAnimal(animal)"
            style="height: 101px; margin: 1%"
            hover
          >
            <v-container>
              <v-overlay
                opacity="0"
                absolute
                style="
                  justify-content: start;
                  align-items: start;
                  pointer-events: none;
                  margin-top: 5px;
                "
              >
                <v-chip
                  style="color: white; font-weight: 500; margin-bottom: -3px"
                  small
                  :color="
                    animal.tag_label &&
                    animal.tag_color &&
                    animal.tag_color != 'UNDEFINED'
                      ? 'oc_' + animal.tag_color.toLowerCase() + ' darken-1'
                      : 'grey darken-1'
                  "
                >
                  {{ animal.tag_label ? animal.tag_label : "UND" }}
                </v-chip>
              </v-overlay>
              <v-row dense style="margin: 0 !important; padding: 0 !important">
                <v-col cols="5">

                  <v-img
                    ref="animalCardImage"
                    height="76px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    @error="() => handleImageError(animal)"
                    :src="animal.imageUrl"
                  ></v-img>
                </v-col>
                <v-col cols="7">
                  <div class="text-caption">
                    {{ animal.color ? animal.color : "Unknown" }}
                  </div>
                  <div class="text-caption">
                    {{ animal.dob ? animal.dob : "Unknown" }}
                  </div>
                  <div class="text-caption">
                    {{ animal.sex ? formatString(animal.sex) : "Unknown" }}
                  </div>
                  <div class="text-caption">
                    {{
                      animal.classfication
                        ? formatString(animal.classification)
                        : "Unknown"
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
        <div
          class="left-column"
          v-if="animals.length > 0 && displayingOneAnimal"
        >
          <v-card
            v-if="displayingOneAnimal && displayCurrentAnimal"
            elevation="2"
            style="height: 100%"
          >
            <v-overlay
              opacity="0"
              absolute
              style="justify-content: start; align-items: start"
            >
              <div class="image_selector_ac">
                <span class="image_selector_icons_ac" style="margin-left: 117%">
                  <v-icon medium style="color: red" @click="showOriginal"
                    >mdi-close</v-icon
                  ></span
                >
                <span
                  class="image_selector_icons_ac"
                  style="margin-left: 5em; margin-top: 10em"
                  ><v-icon
                    medium
                    :color="front_image_select == true ? 'primary' : null"
                    @click="toggle_animal_image()"
                    >mdi-chevron-left</v-icon
                  ><v-icon
                    medium
                    :color="front_image_select == false ? 'primary' : null"
                    @click="toggle_animal_image()"
                    >mdi-chevron-right</v-icon
                  >
                </span>
              </div>
            </v-overlay>
            <v-img
              ref="animalImage"
              height="35%"
              style="border-radius: 4px"
              aspect-ratio="1"
              @error="() => handleImageError(displayCurrentAnimal)"
              :src="
                front_image_select
                  ? displayCurrentAnimal.imageUrl
                  : displayCurrentAnimal.imageUrlBody
              "
            >
              <v-overlay
                opacity="0"
                absolute
                style="justify-content: start; align-items: start"
              >
                <v-chip
                  style="color: white; font-weight: 500"
                  small
                  :color="
                    displayCurrentAnimal.tag_color &&
                    displayCurrentAnimal.tag_color != 'undefined'
                      ? 'oc_' +
                        displayCurrentAnimal.tag_color.toLowerCase() +
                        ' darken-1'
                      : 'grey darken-1'
                  "
                >
                  {{
                    displayCurrentAnimal.tag_label
                      ? displayCurrentAnimal.tag_label
                      : "UND"
                  }}
                </v-chip>
              </v-overlay>
            </v-img>

            <div
              style="
                font-size: 15px;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-left: 10px;
                margin-top: 10px;
                color: #0090a4;
                display: flex;
                justify-content: space-between;
              "
            >
              {{
                displayCurrentAnimal.name
                  ? displayCurrentAnimal.name
                  : "Unknown"
              }}
            </div>
            <div
              style="margin-top: 10px; margin-left: 10px; margin-right: 10px"
            >
              <v-select
                hide-details
                outlined
                append-icon=""
                readonly
                label="Species"
                dense
                :items="[
                  { value: 'BISON', text: 'Bison' },
                  { value: 'CAMEL', text: 'Camel' },
                  { value: 'CAT', text: 'Cat' },
                  { value: 'CATTLE', text: 'Cattle' },
                  { value: 'DOG', text: 'Dog' },
                  { value: 'ELK', text: 'Elk' },
                  { value: 'GOAT', text: 'Goat' },
                  { value: 'HORSE', text: 'Horse' },
                  { value: 'SHEEP', text: 'Sheep' },
                  { value: 'SWINE', text: 'Swine' },
                  { value: 'OTHER', text: 'Other' },
                ]"
                v-model="displayCurrentAnimal.species"
              />
            </div>
            <div
              style="margin-top: 20px; margin-left: 10px; margin-right: 10px"
            >
              <v-text-field
                hide-details
                outlined
                readonly
                label="Breed"
                dense
                v-model="displayCurrentAnimal.breed"
              />
            </div>
            <div
              style="margin-top: 20px; margin-left: 10px; margin-right: 10px"
            >
              <v-text-field
                hide-details
                outlined
                readonly
                label="Color"
                dense
                v-model="displayCurrentAnimal.color"
              />
            </div>
            <div
              style="margin-top: 20px; margin-left: 10px; margin-right: 10px"
            >
              <v-select
                hide-details
                outlined
                append-icon=""
                readonly
                label="Sex"
                dense
                :items="[
                  { value: 'MALE', text: 'Male' },
                  { value: 'FEMALE', text: 'Female' },
                  { value: 'OTHER', text: 'Other' },
                ]"
                v-model="displayCurrentAnimal.sex"
              />
            </div>
            <div
              style="margin-top: 20px; margin-left: 10px; margin-right: 10px"
            >
              <v-select
                hide-details
                outlined
                append-icon=""
                readonly
                label="Classification"
                dense
                hide-selected
                :items="[
                  { value: 'BULL', text: 'Bull' },
                  { value: 'CALF', text: 'Calf' },
                  { value: 'COW', text: 'Cow' },
                  { value: 'FREEMARTIN', text: 'Freemartin' },
                  { value: 'HEIFER', text: 'Heifer' },
                  { value: 'STEER', text: 'Steer' },
                  { value: 'OTHER', text: 'Other' },
                ]"
                v-model="displayCurrentAnimal.classification"
              />
            </div>
          </v-card>
        </div>
        <div class="left-column" v-if="!animals.length">
          <v-card elevation="2" style="height: 100%">
            <div style="margin-top: 85%; margin-left: 10%">
              <v-img
                width="150px"
                height="150px"
                src="../assets/BETSY_wave_smile.png"
                contain
              ></v-img>
            </div>
            <v-spacer></v-spacer>
            <div
              style="
                font-size: 14px;
                letter-spacing: 2px;
                color: #0090a4;
                text-align: center;
              "
            >
              ANIMAL
            </div>
            <div
              style="
                font-size: 14px;
                letter-spacing: 2px;
                color: #0090a4;
                text-align: center;
              "
            >
              IDENTIFICATION
            </div>
            <div
              style="
                font-size: 14px;
                letter-spacing: 2px;
                color: #0090a4;
                text-align: center;
              "
            >
              PENDING
            </div>
          </v-card>
        </div> -->
      </v-col>
      <v-col>
        <div class="middle">
          <div>
            <div class="container-vp">
              <div class="video-container paused vjs-control-bar">
                <div class="panZoom-container">
                  <canvas
                    id="imageCanvas"
                    ref="imageCanvas"
                    width="952"
                    height="535.5"
                    @click="handleCanvasClick"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="panZoom-container">  <canvas id="imageCanvas" ref="imageCanvas" width="952" height="535.5"></canvas></div> -->
        <img
          slot="image"
          alt=""
          :src="`${image_path}`"
          ref="image"
          style="display: none"
        />
        <img
          src="../assets/Onecup-Player.png"
          style="display: none"
          id="logo"
        />
        <img
          src="../assets/icon_cowcalving_colour.svg"
          style="display: none"
          id="calving_cow_image"
        />
        <img
          src="../assets/icon_cowchinresting_colour.svg"
          style="display: none"
          id="chinresting_cow_image"
        />
        <img
          src="../assets/icon_cowmounting_colour.svg"
          style="display: none"
          id="mounting_cow_image"
        />
        <img
          src="../assets/icon_cowsitting_colour.svg"
          style="display: none"
          id="laying_cow_image"
        />
        <img
          src="../assets/icon_cowstanding_colour.svg"
          style="display: none"
          id="standing_cow_image"
        />
        <img
          src="../assets/icon_cowheat_color.svg"
          style="display: none"
          id="heat_cow_image"
        />
        <img
          src="../assets/icon_trex_colour.svg"
          style="display: none"
          id="predator"
        />
      </v-col>
      <v-col class="ml-2" v-show="showSideContainers">
        <div class="right-column">
          <v-card elevation="2" height="24em">
                  <v-tabs class="table_tabs_vp" grow height="25">
                    <v-tab>Overlay Controls</v-tab>
                  </v-tabs>

                  <v-container fluid class="container-vp">
                    <v-card-title class="justify-start inference-title">
                      Annotations
                    </v-card-title>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="showbos">
                        <template #label>
                          <span>Bounding Box</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="bodyparts">
                        <template #label>
                          <span>Body Parts</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="keypoints">
                        <template #label>
                          <span>Key Points</span>
                        </template>
                      </v-switch>
                    </v-container>
                  </v-container>

                  <v-container fluid class="container-vp label-container">
                    <v-card-title class="justify-start inference-title">
                      Visuals
                    </v-card-title>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="bbox_fill">
                        <template #label>
                          <span>Color Fill</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="activityIcons">
                        <template #label>
                          <span>Icons</span>
                        </template>
                      </v-switch>
                    </v-container>
                  </v-container>

                  <v-container fluid class="container-vp label-container">
                    <v-card-title class="justify-start inference-title">
                      Labels
                    </v-card-title>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="labels">
                        <template #label>
                          <span>Anatomy Labels</span>
                        </template>
                      </v-switch>
                    </v-container>
                    <v-container fluid class="toggle-switches container-vp">
                      <v-switch v-model="inference_labels">
                        <template #label>
                          <span>Classifications </span>
                        </template>
                      </v-switch>
                    </v-container>
                  </v-container>
                </v-card>
          <div style="width: 100%; margin-top: 2%">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <input
                type="text"
                placeholder="Search Tag"
                style="
                  border: 1px solid silver;
                  padding: 2px;
                  border-radius: 5px;
                  width: 40%;
                "
              />
              <v-btn x-small text @click="sortBy('letter')" elevation="2"
                >A-Z</v-btn
              >
              <v-btn x-small text @click="sortBy('color')" elevation="2"
                >Color</v-btn
              >
            </div>

            <div style="height: 11.5em">
              <v-card
                style="height: 100%"
                class="overflow-auto mt-1"
                elevation="2"
              >
                <v-card-text>
                  <div v-if="!(tags_list.length > 0)">
                    <div style="margin-top: 30px">
                      <div
                        style="
                          font-size: 14px;
                          letter-spacing: 2px;
                          color: #0090a4;
                          text-align: center;
                        "
                      >
                        NO
                      </div>
                      <div
                        style="
                          font-size: 14px;
                          letter-spacing: 2px;
                          color: #0090a4;
                          text-align: center;
                        "
                      >
                        TAGS
                      </div>
                      <div
                        style="
                          font-size: 14px;
                          letter-spacing: 2px;
                          color: #0090a4;
                          text-align: center;
                        "
                      >
                        READ
                      </div>
                    </div>
                  </div>
                  <div v-else style="display: flex; flex-wrap: wrap; gap: 5px">
                    <v-chip
                      v-for="tags in tags_list"
                      :key="tags.tracking_id"
                      class="chip-style"
                      small
                      :class="[
                        isSelected(tags.tracking_id)
                          ? ''
                          : tags.tag_color
                          ? `oc_${tags.tag_color.toLowerCase()} darken-1`
                          : 'grey darken-1',
                      ]"
                      :style="{
                        backgroundColor: isSelected(tags.tracking_id)
                          ? 'white'
                          : '',
                        color: isSelected(tags.tracking_id)
                          ? '#0090a4'
                          : 'white',
                        border: isSelected(tags.tracking_id)
                          ? '1px solid #0090a4'
                          : '1px solid white',
                      }"
                      @click="changeSelectedTrackingID(tags.tracking_id)"
                    >
                      {{ tags.tag_label }}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div></v-col
      >
    </v-row>
    <div style="display: flex; justify-content: space-between; margin-top: 1%">
      <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
        >Version No: 2.2.0</span
      >

      <span>
        <twitter-button class="share-button--outline" btnText />
        <facebook-button class="share-button--outline" btnText />
        <linkedIn-button class="share-button--outline" btnText />
        <telegram-button class="share-button--outline" btnText />
      </span>

      <span style="font-size: 14px; letter-spacing: 2px; color: #0090a4"
        >Last updated: July 15 '24</span
      >
    </div>
  </v-container>
</template>

<script>
import { eventBus } from "../main";
import TwitterButton from "../utilities/vuesharebuttons/components/TwitterButton.vue";
import FacebookButton from "../utilities/vuesharebuttons/components/FacebookButton";
import LinkedInButton from "../utilities/vuesharebuttons/components/LinkedInButton";
import TelegramButton from "../utilities/vuesharebuttons/components/TelegramButton";
import Panzoom from "@panzoom/panzoom";
import axios from "axios";
import moment from "moment";
import placeholder_img from "@/assets/cover_brown.png";

export default {
  components: {
    TwitterButton,
    FacebookButton,
    LinkedInButton,
    TelegramButton,
    //  "vue-load-image": VueLoadImage,
  },
  props: ["img_src"],
  data() {
    return {
      tabs: null,
      showSideContainers: window.innerWidth >= 1450,
      has_gender: false,
      has_gender_json: false,
      has_grazing: false,
      has_grazing_json: false,
      show_grazing: false,
      show_gender: false,
      isShowGrazing: false,
      isShowGender: false,
      json_loading: false,
      bbox_fill: false,
      isbbox_fill: false,
      image_path: "",
      json_annotation_div: null,
      json_data: null,
      camera_id: "",
      image_time: "",
      is_analytics: false,
      body_parts_list: [],
      tags_list: [],
      animals: [],
      selectedTrackingID: null,
      placeholder_img: placeholder_img,
      displayCurrentAnimal: [],
      front_image_select: true,
      displayingOneAnimal: false,
      // animal : [{}]

      // annotations
      list_of_annotations: [],
      list_of_bodyparts: [],
      list_of_keypoints: [],
      list_of_lines: [],
      list_of_all_bbox_classifications: [],

      has_contraction: false,
      list_of_contractions: [],

      has_species: false,
      list_of_species: {},

      has_activity: false,
      list_of_activities: {},

      has_age: false,
      list_of_ages: [],

      has_suckling: false,
      list_of_sucklings: [],

      has_predator: false,
      list_of_predators: [],

      has_delivery: false,
      list_of_deliverys: [],

      has_mounting: false,
      list_of_mountings: [],

      has_resting_chin: false,
      list_of_resting_chins: [],

      has_in_heat: false,
      list_of_in_heats: [],

      tab: null,

      topology38: [
        [7, 6],
        [12, 11],
        [15, 6],
        [15, 11],
        [6, 5],
        [11, 10],
        [10, 5],
        [10, 0],
        [5, 0],
        [3, 4],
        [1, 2],
        [27, 29],
        [29, 28],
        [24, 25],
        [25, 26],
        [15, 16],
        [16, 19],
        [19, 24],
        [19, 27],
        [19, 21],
        [21, 37],
        [21, 38],
        [37, 36],
        [38, 36],
        [21, 20],
        [20, 22],
        [22, 23],
        [20, 32],
        [20, 33],
        [33, 35],
        [35, 34],
        [32, 30],
        [30, 31],
        [0, 1],
        [3, 1],
        [4, 1],
        [5, 3],
        [10, 4],
      ],
      topology52: [
        [10, 5],
        [10, 11],
        [5, 6],
        [6, 7],
        [11, 12],
        [0, 5],
        [0, 10],
        [1, 2],
        [3, 4],
        [6, 15],
        [11, 15],
        [15, 16],
        [16, 19],
        [19, 20],
        [20, 21],
        [21, 22],
        [22, 23],
        [23, 24],
        [24, 25],
        [25, 26],
        [26, 27],
        [27, 28],
        [2, 17],
        [17, 18],
        [31, 32],
        [32, 30],
        [30, 29],
        [29, 33],
        [35, 37],
        [37, 36],
        [36, 34],
        [34, 38],
        [40, 42],
        [42, 39],
        [39, 41],
        [41, 43],
        [45, 47],
        [47, 46],
        [46, 44],
        [44, 48],
        [6, 8],
        [8, 9],
        [11, 13],
        [13, 14],
        [49, 50],
        [49, 51],
      ],
      showbos: true,
      bodyparts: true,
      keypoints: false,
      labels: true,
      inference_labels: true,
      show_contraction: false,
      show_activity: false,
      show_age: false,
      show_suckling: false,
      show_delivery: false,
      show_mounting: false,
      show_resting_chin: false,
      show_in_heat: false,
      show_predator: false,
      activityIcons: true,
      fullscreen: false,
      show_filtered: false,

      json_cache: {},
      init_load_ready: false,

      show_classification: false,

      image_width: 2560,
    };
  },
  watch: {
    showbos() {
      this.updateCanvas();
    },
    selectedTrackingID() {
      this.updateCanvas();
    },
    activityIcons() {
      this.updateCanvas();
    },
    bodyparts() {
      this.updateCanvas();
    },
    keypoints() {
      this.updateCanvas();
    },
    labels() {
      if (this.labels) {
        this.showbos = true;
      }

      this.updateCanvas();
    },
    inference_labels() {
      this.updateCanvas();
    },
    bbox_fill() {
      this.updateCanvas();
    },
    show_grazing() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_gender() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_predator() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_contraction() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_activity() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_age() {
      if (!this.inference_labels) {
        this.inference_labels = true;
      }
      this.updateCanvas();
    },
    show_classification() {
      if (this.show_classification) {
        this.labels = true;
      }

      this.updateCanvas();
    },
    show_suckling() {
      this.updateCanvas();
    },
    show_filtered() {
      this.updateCanvas();
    },
    show_delivery() {
      this.updateCanvas();
    },
    show_mounting() {
      this.updateCanvas();
    },
    show_resting_chin() {
      this.updateCanvas();
    },
    show_in_heat() {
      this.updateCanvas();
    },
    async img_src() {
      this.update_path();
    },
  },
  methods: {
    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1450;
    },
    cropped_url(id, timestamp, customer_id, type) {
      //Animal image 
      let ymd = moment(timestamp).format("YYYY/MM/DD")
      let total_date = moment(timestamp).format(
        "YYYY-MM-DD_HH-mm-ss"
      );
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${customer_id}/animals/images/${ymd}/${id}_${total_date}_${type}.jpg`;
    },
    toggle_animal_image() {
      this.front_image_select = !this.front_image_select;
    },
    handleImageError(currentAnimal) {
      let foundAnimalIndex = this.animals.indexOf(currentAnimal);
      this.animals[foundAnimalIndex].imageUrl = this.placeholder_img;
      this.animals[foundAnimalIndex].imageUrlBody = this.placeholder_img;
    },
    showOriginal() {
      this.displayingOneAnimal = false;
      this.selectedTrackingID = "";
    },
    displayAnimal(animal) {
      if (this.displayCurrentAnimal) {
        this.displayCurrentAnimal = {};
      }
      this.displayCurrentAnimal = animal;
      if (animal.breed && animal.breed == "Holstien") {
        this.displayCurrentAnimal.breed = "Holstein";
      }

      for (const key in this.displayCurrentAnimal) {
        if (this.displayCurrentAnimal[key] === null) {
          this.displayCurrentAnimal[key] = "Unknown";
        }
      }
      let foundTag = this.tags_list.find(
        (tag) => tag.tag_label === this.displayCurrentAnimal.tag_label
      );
      if (foundTag) {
        this.selectedTrackingID = foundTag.tracking_id;
      }
      this.displayingOneAnimal = true;
    },
    formatString(str) {
      if (str) {
        const strLower = str.toLowerCase();
        const strReq = strLower.charAt(0).toUpperCase() + strLower.slice(1);
        return strReq;
      }
      return str;
    },
    sortBy(prop) {
      this.tags_list.sort((a, b) => {
        const sortProperty = prop === "letter" ? "tag_label" : "tag_color";
        if (a[sortProperty] < b[sortProperty]) {
          return -1;
        }
        if (a[sortProperty] > b[sortProperty]) {
          return 1;
        }
        return 0;
      });
    },
    handleCanvasClick(event) {
      var canvas = this.$refs.imageCanvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.offsetX || event.clientX - rect.left;
      const y = event.offsetY || event.clientY - rect.top;

      let currentBBOXs = this.list_of_all_bbox_classifications;
      for (let i = 0; i < currentBBOXs.length; i++) {
        var x1 = this.scaleX(currentBBOXs[i].x1);
        var y1 = this.scaleY(currentBBOXs[i].y1);
        var w = this.scaleX(currentBBOXs[i].w);
        var h = this.scaleY(currentBBOXs[i].h);

        if (x >= x1 && x <= x1 + w && y >= y1 && y <= y1 + h) {
          this.changeSelectedTrackingID(currentBBOXs[i].tracking_id);
          break;
        }
      }
    },
    isSelected(trackingId) {
      return trackingId === this.selectedTrackingID;
    },
    changeSelectedTrackingID(tracking_id) {
      if (this.selectedTrackingID == tracking_id) {
        this.showOriginal();
      } else {
        this.selectedTrackingID = tracking_id;
        //find tag with tracking id
        var foundTag = this.tags_list.find(
          (tag) => tag.tracking_id === tracking_id
        );
        if (foundTag) {
          var tag_label = foundTag.tag_label;
          var foundAnimal = this.animals.find(
            (animal) => animal.tag_label === tag_label
          );
          if (foundAnimal) {
            this.displayAnimal(foundAnimal);
          }
        }
      }
    },
    async update_path() {
      this.tags_list = [];
      this.animals = [];
      this.showOriginal();
      this.displayCurrentAnimal = {};
      this.is_analytics = false;
      this.image_path = this.img_src[1];
     
      const getMeta = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
          img.src = url;
        });

      this.image_width = (await getMeta(this.image_path)).naturalWidth;
      if (Object.prototype.hasOwnProperty.call(this.img_src[0][0], 'img')) {
        let img = this.img_src[0][0].img;
      let tags_list = img.tags;
      if (tags_list && tags_list.length > 0) {
        let customer_id = img.customer_id;
        let query = this.buildQuery(customer_id, tags_list);

        try {
          const response = await axios.post(
            img.hasura_url,
            {
              query: query,
            },
            {
              headers: img.hasura_header,
            }
          );
          const tags = response.data.data.list_animals;
          // this.animals = [...tags];

          tags.forEach((tag) => {
            const matchingTags = this.image_data.tags.filter((imgTag) => {
              const labelMatch =
                imgTag.tag_label.toLowerCase() === tag.tag_label.toLowerCase();
              let tagColorMatch = false;
              if (
                (imgTag.tag_color === undefined &&
                  tag.tag_color === undefined) ||
                (imgTag.tag_color &&
                  tag.tag_color &&
                  imgTag.tag_color.toLowerCase() ===
                    tag.tag_color.toLowerCase())
              ) {
                tagColorMatch = true;
              }

              return labelMatch && tagColorMatch;
            });

            if (matchingTags.length > 0) {
              matchingTags.forEach((matchingTag) => {
                this.tags_list.push({
                  tag_label: matchingTag.tag_label,
                  tag_color: matchingTag.tag_color,
                  tracking_id: matchingTag.tracking_id,
                });

                // use this
                let imageUrl = tag.image_id
                  ? this.cropped_url(
                      tag.image_id,
                      tag.image_timestamp,
                      tag.customer_id,
                      "head"
                    )
                  : "";
                tag.imageUrl = imageUrl;
                tag.imageUrlBody = tag.image_id
                  ? this.cropped_url(
                      tag.image_id,
                      tag.image_timestamp,
                      tag.customer_id,
                      "body"
                    )
                  : "";
                this.animals.push(tag);
              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      }

      this.processAnnotations(this.img_src[0][0].img);  
      } else if (Object.prototype.hasOwnProperty.call(this.img_src[0][0], 'analytics')){
        this.is_analytics = true;
        let img = {};
        let analytics = this.img_src[0][0].analytics;
        img.body_parts_list =[
          ...(analytics.tail_bbox ? [{ bbox: analytics.tail_bbox, label: "tail" }] : []),
          ...(analytics.tag_1_bbox && analytics.tag_1_conf !== undefined
            ? [{ bbox: analytics.tag_1_bbox, conf: analytics.tag_1_conf, label: "tag" }]
            : []),
          ...(analytics.tag_2_bbox && analytics.tag_2_conf !== undefined
            ? [{ bbox: analytics.tag_2_bbox, conf: analytics.tag_2_conf, label: "tag" }]
            : []),
        ]
        let classifications = [];
        if(analytics.contraction_label && analytics.contraction_conf) {
          classifications.push(analytics.contraction_label)
          classifications.push(analytics.contraction_conf)
        }
        if(analytics.contraction_label_2 && analytics.contraction_conf_2) {
          classifications.push(analytics.contraction_label_2)
          classifications.push(analytics.contraction_conf_2)
        }
        let pgies_object = {
          bbox: analytics.bbox ? analytics.bbox : undefined,
          tracking_id: analytics.tracking_id ? analytics.tracking_id : undefined,
          classifications: classifications,
        }

        img.pgies = [pgies_object]
        this.processAnnotations(img);  
      }
      

      await this.$nextTick();
      this.updateCanvas();
    },
    
    buildQuery(customerId, tagLabels) {
      let query = `query {
    list_animals(where: {customer_id: {_eq: "${customerId}"}`;

      if (tagLabels && tagLabels.length > 0) {
        // Include tag labels directly as strings in the array
        const tagsFilter = tagLabels
          .map((label) => `"${label.tag_label}"`)
          .join(", ");
        query += `, tag_label: {_in: [${tagsFilter}]}`;
      }

      query += `}) {
        tag_label
        tag_color
        rfid_current
        sex
        classification
        herd_id
        herd_name
        color
        name
        dob
        species
        breed
        image_timestamp
        id
        image_id
      }}`;

      return query;
    },

    previousImage() {
      eventBus.$emit("previousImage", this.image_index);
    },
    nextImage() {
      eventBus.$emit("nextImage", this.image_index);
    },
    close() {
      var canvas = this.$refs.imageCanvas;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$nextTick(() => {
        this.updateCanvas();
      });
    },
    scaleX(xCoord) {
      const canvasWidth = 952;
      const image = this.$refs.image;
      const imgAspectRatio = image.naturalWidth / image.naturalHeight;
      const canvasAspectRatio = canvasWidth / this.canvasHeight;

      let scaleFactor;
      if (imgAspectRatio < canvasAspectRatio) {
        scaleFactor = canvasWidth / image.naturalWidth;
      } else {
        scaleFactor = 535.5 / image.naturalHeight;
      }

      return Math.round(xCoord * scaleFactor);
    },
    scaleY(yCoord) {
      const canvasHeight = 535.5;
      const image = this.$refs.image;
      const imgAspectRatio = image.naturalWidth / image.naturalHeight;
      const canvasAspectRatio = 952 / canvasHeight;

      let scaleFactor;
      if (imgAspectRatio < canvasAspectRatio) {
        scaleFactor = 952 / image.naturalWidth;
      } else {
        scaleFactor = canvasHeight / image.naturalHeight;
      }

      return Math.round(yCoord * scaleFactor);
    },
    confToPercent(conf) {
      return (conf * 100).toFixed(0) + "%";
    },
    processAnnotations(j_data) {
      this.list_of_annotations = [];
      this.list_of_all_bbox_classifications = [];
      this.list_of_bodyparts = [];
      this.list_of_keypoints = [];
      this.list_of_lines = [];
      this.list_of_contractions = [];
      this.list_of_activities = {};
      this.list_of_ages = [];
      this.list_of_sucklings = [];
      this.list_of_species = {};
      this.has_contraction = false;
      this.has_activity = false;
      this.has_species = false;
      this.has_age = false;
      this.has_suckling = false;
      if (j_data) {
        const { body_parts_list, pgies } = j_data;
 

        if (body_parts_list && body_parts_list.length > 0) {
          this.body_parts_list = body_parts_list;
        }

        if (pgies && pgies.length > 0) {
          pgies.forEach((pgie) => {
            if (Object.prototype.hasOwnProperty.call(pgie, "bbox")) {
              let bboxObj = {
                x1: pgie.bbox.x,
                y1: pgie.bbox.y,
                w: pgie.bbox.w,
                h: pgie.bbox.h,
                species: pgie.class_label,
                speciesConf: pgie.class_conf,
                tracking_id: pgie.tracking_id,
              };

              if (
                Object.prototype.hasOwnProperty.call(pgie, "classifications")
              ) {
                const {
                  activity_conf,
                  activity_label,
                  age_conf,
                  age_label,
                  breed_label,
                  breed_conf,
                  contraction_label,
                  contraction_conf,
                  species_label,
                  species_conf,
                } = pgie.classifications;

                // Age label
                if (age_label) {
                  this.has_age = true;
                  if (age_conf) {
                    bboxObj.ageConf = this.confToPercent(age_conf);
                  }
                  bboxObj.age = age_label;
                }

                if (species_label) {
                  // this.has_age = true;
                  if (species_conf) {
                    bboxObj.conf = species_conf;
                  }
                  bboxObj.label = species_label;
                }

                // Activity Label
                if (activity_label) {
                  this.has_activity = true;
                  if (activity_conf) {
                    bboxObj.activityConf = this.confToPercent(activity_conf);
                  }
                  bboxObj.activity = activity_label;
                }

                //Tail UP
                if (contraction_label && contraction_label === "tail_up") {
                  this.has_contraction = true;
                  bboxObj.contraction = contraction_label;
                  if (contraction_conf) {
                    bboxObj.contractionConf =
                      this.confToPercent(contraction_conf);
                  }
                }

                // Predator
                if (breed_label && breed_label === "coyote") {
                  this.has_predator = true;
                  bboxObj.predator = breed_label;
                  if (breed_conf) {
                    bboxObj.predatorConf = this.confToPercent(breed_conf);
                  }
                }
              }

              let compositeKey = `${bboxObj.tracking_id}-${bboxObj.label}-${bboxObj.x1}-${bboxObj.y1}-${bboxObj.w}-${bboxObj.h}`;
              if (
                !this.list_of_all_bbox_classifications.some(
                  (e) =>
                    `${e.tracking_id}-${e.label}-${e.x1}-${e.y1}-${e.w}-${e.h}` ===
                    compositeKey
                )
              ) {
                this.list_of_all_bbox_classifications.push(bboxObj);
              }
            }
          });
        }
      }
    },
    drawIcon(ctx, icon, index, bbox) {
      const basePosition = 5;
      const offset = 40;
      const position = basePosition + offset * index;

      ctx.beginPath();
      const iconX = this.scaleX(bbox.x1) + this.scaleX(bbox.w) - position;
      const iconY = this.scaleY(bbox.y1) - 25;
      ctx.arc(iconX, iconY, 15, 0, 2 * Math.PI);
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.drawImage(icon, iconX - 25 / 2, iconY - 25 / 2);
    },
    drawStackedText(ctx, text, index, bbox) {
      ctx.font = "bold 85% Arial";
      ctx.fillStyle = "white";
      ctx.textBaseline = "bottom";
      ctx.textAlign = "right";
      // Calculate text dimensions
      const textWidth = ctx.measureText(text).width;
      const textMetrics = ctx.measureText(text);
      const textHeight =
        textMetrics.actualBoundingBoxAscent +
        textMetrics.actualBoundingBoxDescent;

      const textX = this.scaleX(bbox.x1) + this.scaleX(bbox.w) - 5;
      const verticalOffset = 5;
      const textY =
        this.scaleY(bbox.y1) +
        this.scaleY(bbox.h) -
        (textHeight + verticalOffset) * index -
        verticalOffset;

      const rectanglePadding = 2;
      const backgroundHeight = textHeight + rectanglePadding * 2;
      const backgroundWidth = textWidth + rectanglePadding * 2;

      ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
      ctx.fillRect(
        textX - textWidth - rectanglePadding,
        textY - textHeight - rectanglePadding - 2,
        backgroundWidth,
        backgroundHeight
      );

      // Draw the text over the background
      ctx.fillStyle = "white"; // Text color
      ctx.fillText(text, textX, textY);
    },
    drawInferenceLabels(ctx, arr) {
      for (let i = 0; i < arr.length; i++) {
        let textIndex = 0;
        var bbox = {
          x1: arr[i].x1,
          y1: arr[i].y1,
          w: arr[i].w,
          h: arr[i].h,
        };
        if (this.inference_labels) {
          if (this.show_age) {
            if ("age" in arr[i]) {
              var ageLabel = arr[i].age;
              var formattedAgeLabel =
                ageLabel.charAt(0).toUpperCase() + ageLabel.slice(1);
              if (formattedAgeLabel.toUpperCase() === "Cow".toUpperCase()) {
                formattedAgeLabel = "Adult";
              }
              var ageLabelConf;
              let ageLabelText;
              if ("ageConf" in arr[i]) {
                ageLabelConf = arr[i].ageConf;
                ageLabelText = `Age: ${formattedAgeLabel}, ${ageLabelConf}`;
              } else {
                ageLabelText = `Age: ${formattedAgeLabel}`;
              }

              this.drawStackedText(ctx, ageLabelText, textIndex, bbox);
              textIndex++;
            }
          }
          if (this.show_activity) {
            // Activity classification
            if ("activity" in arr[i]) {
              let activityLabel = arr[i].activity
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(arr[i].activity.includes("standing") ? "/" : " ");

              let activityConf = arr[i].activityConf
                ? `, ${arr[i].activityConf}`
                : "";
              let activityLabelText = `Activity: ${activityLabel}${activityConf}`;
              this.drawStackedText(ctx, activityLabelText, textIndex, bbox);
              textIndex++;
            }
          }

          if (
            this.show_resting_chin &&
            "resting_chin" in arr[i] &&
            arr[i].resting_chin === "resting_chin"
          ) {
            let restingChinLabel = arr[i].resting_chin
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .reverse()
              .join(" ");
            let restingChinConf = arr[i].resting_chinConf
              ? `, ${arr[i].resting_chinConf}`
              : "";
            let restingChinLabelText = `${restingChinLabel}${restingChinConf}`;
            this.drawStackedText(ctx, restingChinLabelText, textIndex, bbox);
            textIndex++;
          }

          if (
            this.show_mounting &&
            "mounting" in arr[i] &&
            arr[i].mounting != "not_mounting"
          ) {
            let mountingLabelText = `Mounting: ${
              arr[i].mountingConf ? arr[i].mountingConf : ""
            }`;
            this.drawStackedText(ctx, mountingLabelText, textIndex, bbox);
            textIndex++;
          }

          if (
            this.show_contraction &&
            "contraction" in arr[i] &&
            (arr[i].contraction === "tail_up" ||
              arr[i].contraction === "tail_down")
          ) {
            let contractionLabel = arr[i].contraction
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");

            let contractionConf = arr[i].contractionConf
              ? `, ${arr[i].contractionConf}`
              : "";
            let contractionLabelText = `${contractionLabel}${contractionConf}`;
            this.drawStackedText(ctx, contractionLabelText, textIndex, bbox);
            textIndex++;
          }
          if (
            this.show_in_heat &&
            "in_heat" in arr[i] &&
            arr[i].in_heat === "in_heat"
          ) {
            let heatLabelText = `In Heat: ${
              arr[i].heatConf ? arr[i].heatConf : ""
            }`;
            this.drawStackedText(ctx, heatLabelText, textIndex, bbox);
            textIndex++;
          }

          if (this.show_predator) {
            //Predator
            if ("predator" in arr[i]) {
              let predatorLabel = arr[i].predator;

              let predatorConf = arr[i].predatorConf
                ? `, ${arr[i].predatorConf}`
                : "";
              let predatorLabelText = `Predator: ${predatorLabel}${predatorConf}`;
              this.drawStackedText(ctx, predatorLabelText, textIndex, bbox);
              textIndex++;
            }
          }
        }
      }
    },
    drawBBOXByList(ctx, arr, fillStyle, strokeStyle) {
      for (let i = 0; i < arr.length; i++) {
        let iconIndex = 0;
        var calving_icon = document.getElementById("calving_cow_image");
        var chinresting_icon = document.getElementById("chinresting_cow_image");
        var mounting_icon = document.getElementById("mounting_cow_image");
        var laying_icon = document.getElementById("laying_cow_image");
        var standing_icon = document.getElementById("standing_cow_image");
        var heat_icon = document.getElementById("heat_cow_image");
        var predator_icon = document.getElementById("predator");

        var bbox = {
          x1: arr[i].x1,
          y1: arr[i].y1,
          w: arr[i].w,
          h: arr[i].h,
        };

        ctx.fillStyle =
          this.$store.getters.getBBOXColorCode[arr[i].type]?.fillStyle ||
          fillStyle ||
          "rgba(242, 114, 0, 0.4)";
        ctx.lineWidth = 1;
        ctx.strokeStyle =
          this.$store.getters.getBBOXColorCode[arr[i].type]?.strokeStyle ||
          strokeStyle ||
          "rgba(242, 114, 0)";

        if (this.activityIcons) {
          if (this.show_activity) {
            // Activity classification
            if ("activity" in arr[i]) {
              this.drawIcon(
                ctx,
                arr[i].activity === "standing_walking"
                  ? standing_icon
                  : laying_icon,
                iconIndex,
                bbox
              );
              iconIndex++;
            }
          }

          if (this.show_predator) {
            //Predator
            if ("predator" in arr[i]) {
              this.drawIcon(ctx, predator_icon, iconIndex, bbox);
              iconIndex++;
            }
          }

          if (
            this.show_resting_chin &&
            "resting_chin" in arr[i] &&
            arr[i].resting_chin === "resting_chin"
          ) {
            this.drawIcon(ctx, chinresting_icon, iconIndex, bbox);
            iconIndex++;
          }

          // Mounting
          if (
            this.show_mounting &&
            "mounting" in arr[i] &&
            arr[i].mounting != "not_mounting"
          ) {
            this.drawIcon(ctx, mounting_icon, iconIndex, bbox);
            iconIndex++;
          }

          // In Heat
          if (
            this.show_in_heat &&
            "in_heat" in arr[i] &&
            arr[i].in_heat === "in_heat"
          ) {
            this.drawIcon(ctx, heat_icon, iconIndex, bbox);
            iconIndex++;
          }
          if (
            this.show_contraction &&
            "contraction" in arr[i] &&
            (arr[i].contraction === "tail_up" ||
              arr[i].contraction === "tail_down")
          ) {
            this.drawIcon(ctx, calving_icon, iconIndex, bbox);
            iconIndex++;
          }
        }
      }
    },
    updateCanvas() {
      if (this.init_load_ready) {
        const canvas = this.$refs.imageCanvas;
        const ctx = canvas.getContext("2d");
        var image = this.$refs.image;
        canvas.width = 952;
        canvas.height = 535.5;

        if (!canvas || !image) {
          return;
        }
        const imgAspectRatio = image.naturalWidth / image.naturalHeight;
        const canvasAspectRatio = canvas.width / canvas.height;
        let renderableWidth, renderableHeight, xStart, yStart;

        if (imgAspectRatio < canvasAspectRatio) {
          // Image is narrower than canvas (fit to width)
          renderableWidth = canvas.width;
          renderableHeight =
            image.naturalHeight * (canvas.width / image.naturalWidth);
          xStart = 0;
          yStart = (canvas.height - renderableHeight) / 2;
        } else {
          // Image is wider than canvas (fit to height)
          renderableHeight = canvas.height;
          renderableWidth =
            image.naturalWidth * (canvas.height / image.naturalHeight);
          xStart = (canvas.width - renderableWidth) / 2;
          yStart = 0;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, xStart, yStart, renderableWidth, renderableHeight);
        ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        if (this.showbos) {
          this.drawBBOX(ctx);
        }
        if (this.bodyparts) {
          this.drawBodyParts(ctx);
        }
        if (this.keypoints) {
          this.drawLines(ctx);
          this.drawKeypoints(ctx);
        }

        if (this.activityIcons) {
          this.drawBBOXByList(ctx, this.list_of_all_bbox_classifications);
        }
        if (this.inference_labels) {
          this.drawInferenceLabels(ctx, this.list_of_all_bbox_classifications);
        }

        let temp_local_storage_params = JSON.stringify({
          body: this.showbos,
          bodyparts: this.bodyparts,
          keypoints: this.keypoints,
          labels: this.labels,
          inference_labels: this.inference_labels,
          // classification: this.show_classification,
        });
        localStorage.setItem("image_view_pref", temp_local_storage_params);
      }
    },
    getBodyPartArrayID(str) {
      switch (str) {
        case "head":
          return [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
          ];
        case "tail":
          return [24, 25, 26, 27, 28];
        case "body":
          return [19, 20, 21, 22, 23, 33, 38, 43, 48, 49, 50, 51];
        case "limb":
          return [
            29, 30, 31, 32, 34, 35, 36, 37, 39, 40, 41, 42, 44, 45, 46, 47,
          ];
        default:
          return [];
      }
    },
    get_color(idx) {
      var colorDB = "(4, 24, 146)"; // dark blue
      var colorBrown = "(147, 82, 0)"; // brown
      var colorPink = "(148, 32, 147)"; // dark pink
      var colorGreen = "(3, 143, 0)"; // green
      var colorLB = "(3 84 148)"; // light blue
      var ret;
      if (idx <= 10) {
        ret = colorDB;
      } else if (idx >= 11 && idx <= 14) {
        ret = colorBrown;
      } else if ((idx >= 15 && idx <= 24) || idx == 27 || idx == 28) {
        ret = colorLB;
      } else if (idx >= 25 && idx <= 26) {
        ret = colorPink;
      } else if (idx >= 29 && idx <= 32) {
        ret = colorGreen;
      } else {
        ret = colorDB;
      }
      return ret;
    },
    get_point_color(idx) {
      var colorOrange = "(255, 147, 5)"; // orange
      var colorLightPink = "(255, 63, 255)"; // light pink
      var colorBrightGreen = "(13, 249, 0)"; // bright green
      var colorDarkSkyBlue = "(8 150 255)"; // little dark than sky blue
      var colorYellow = "(255, 255, 0)"; //  yellow
      //
      var colorDB = "(4, 24, 146)";
      var colorPink = "(148, 32, 147)";
      var colorGreen = "(3, 143, 0)";
      var colorLB = "(3 84 148)";
      var ret1, ret2;
      if (idx >= 2 && idx <= 4) {
        ret1 = colorYellow;
        ret2 = colorLB;
      } else if (idx == 5 || idx == 10 || (idx >= 24 && idx <= 29)) {
        ret1 = colorOrange;
        ret2 = colorLB;
      } else if (idx == 6 || idx == 11 || (idx >= 30 && idx <= 35)) {
        ret1 = colorBrightGreen;
        ret2 = colorGreen;
      } else if (idx == 7 || idx == 12 || idx == 22 || idx == 23) {
        ret1 = colorLightPink;
        ret2 = colorPink;
      } else {
        ret1 = colorDarkSkyBlue;
        ret2 = colorDB;
      }
      return [ret1, ret2];
    },
    drawBBOX(ctx) {
      for (let i = 0; i < this.list_of_all_bbox_classifications.length; i++) {
        var x1 = this.scaleX(this.list_of_all_bbox_classifications[i].x1);
        var y1 = this.scaleY(this.list_of_all_bbox_classifications[i].y1);
        var w = this.scaleX(this.list_of_all_bbox_classifications[i].w);
        var h = this.scaleY(this.list_of_all_bbox_classifications[i].h);

        if (
          this.list_of_all_bbox_classifications[i].tracking_id ===
          this.selectedTrackingID
        ) {
          ctx.fillStyle = "rgb(0, 144, 164, 0.3)";
        } else {
          ctx.fillStyle =
            this.$store.getters.getBBOXColorCode[
              this.list_of_all_bbox_classifications[i].species
            ]?.fillStyle || "rgba(242, 114, 0, 0.4)";
        }

        if (this.bbox_fill) {
          ctx.fillRect(x1, y1, w, h);
        }

        ctx.lineWidth = 3;

        ctx.strokeStyle =
          this.$store.getters.getBBOXColorCode[
            this.list_of_all_bbox_classifications[i].species
          ]?.strokeStyle || "rgba(242, 114, 0, 0.4)";
        ctx.strokeRect(x1, y1, w, h);

        if (this.labels) {
          var textWidth;
          var textHeight;
          var textMetrics;
          var bboxRectangleHeight;
          var bboxRectangleWidth;

          ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          ctx.font = `bold 85% Arial`;

          let formattedPrimarylabel = "";

          if (this.list_of_all_bbox_classifications[i].species != "animal") {
            if (this.list_of_all_bbox_classifications[i].species) {
              formattedPrimarylabel =
                this.list_of_all_bbox_classifications[i].species
                  .charAt(0)
                  .toUpperCase() +
                this.list_of_all_bbox_classifications[i].species.slice(1);
              const speciesConfPercent = (
                this.list_of_all_bbox_classifications[i].speciesConf * 100
              ).toFixed(0);
              var bboxText = `${formattedPrimarylabel}: ${speciesConfPercent}%`;
            }
          } else {
            if (this.list_of_all_bbox_classifications[i].label) {
              formattedPrimarylabel =
                this.list_of_all_bbox_classifications[i].label
                  .charAt(0)
                  .toUpperCase() +
                this.list_of_all_bbox_classifications[i].label.slice(1);
              if (this.list_of_all_bbox_classifications[i].conf) {
                const confPercent = (
                  this.list_of_all_bbox_classifications[i].conf * 100
                ).toFixed(0);
                bboxText = `${formattedPrimarylabel}: ${confPercent}%`;
              }
            }
          }

          if (bboxText) {
            textWidth = ctx.measureText(bboxText).width;
            textMetrics = ctx.measureText(bboxText);
            textHeight =
              textMetrics.actualBoundingBoxAscent +
              textMetrics.actualBoundingBoxDescent;
            bboxRectangleHeight = textHeight + 1;
            bboxRectangleWidth = textWidth + 1;
            ctx.fillRect(
              x1,
              y1 - 7 - textHeight,
              bboxRectangleWidth + 3,
              bboxRectangleHeight + 3
            );

            ctx.fillStyle = "white";
            ctx.fillText(bboxText, x1 + 2, y1 - 6);
          }

          // let tracker =
          //   this.list_of_all_bbox_classifications[i]?.tracking_id ?? null;
          // if (tracker !== null) {
          //   textWidth = ctx.measureText(tracker).width;
          //   textMetrics = ctx.measureText(tracker);
          //   textHeight =
          //     textMetrics.actualBoundingBoxAscent +
          //     textMetrics.actualBoundingBoxDescent;
          //   bboxRectangleHeight = textHeight + 1;
          //   bboxRectangleWidth = textWidth + 1;
          //   let label_y_pos = y1 + 3;
          //   let rectangle_width = bboxRectangleWidth + 3;
          //   let label_x_pos = x1 + w - bboxRectangleWidth - 6;
          //   ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          //   ctx.fillRect(
          //     label_x_pos,
          //     label_y_pos,
          //     rectangle_width,
          //     bboxRectangleHeight + 3
          //   );
          //   label_y_pos = y1 + 13;
          //   ctx.fillStyle = "white";
          //   ctx.fillText(
          //     tracker,
          //     x1 + y1 - bboxRectangleWidth - 4,
          //     label_y_pos
          //   );
          // }
        }
      }
    },
    drawBodyParts(ctx) {
      let annotationsSource = [];
      for (let i = 0; i < this.body_parts_list.length; i++) {
        if(this.is_analytics) {
          annotationsSource = this.body_parts_list[i];
        } else {
          annotationsSource = this.body_parts_list[i][0];
        }
        if (!annotationsSource.bbox) {
          return;
        }
        var x1 = this.scaleX(annotationsSource.bbox.x);
        var y1 = this.scaleY(annotationsSource.bbox.y);
        var w = this.scaleX(annotationsSource.bbox.w);
        var h = this.scaleY(annotationsSource.bbox.h);

        let label = annotationsSource.label;

        ctx.fillStyle = "rgba(0, 154, 255, 0.3)";
        if (label == "tag") {
          ctx.fillStyle = "rgba(55, 255, 255, 0.4)";
        } else if (label == "head") {
          ctx.fillStyle = "rgba(0, 154, 255, 0.4)";
        } else if (label == "knee") {
          ctx.fillStyle = "rgba(254, 255, 0, 0.4)";
        } else if (label == "hoof") {
          ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
        } else if (label == "tail") {
          ctx.fillStyle = "rgba(255, 66, 161, 0.4)";
        } else {
          ctx.fillStyle = "rgba(96, 217, 55, 0.4)";
        }
        ctx.fillRect(x1, y1, w, h);

        // Set stroke color based on label
        ctx.lineWidth = 3;
        if (label == "tag") {
          ctx.strokeStyle = "rgba(55, 255, 255, 0.4)";
        } else if (label == "head") {
          ctx.strokeStyle = "rgba(0, 154, 255, 0.4)";
        } else if (label == "knee") {
          ctx.strokeStyle = "rgba(254, 255, 0, 0.4)";
        } else if (label == "hoof") {
          ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        } else if (label == "tail") {
          ctx.strokeStyle = "rgba(255, 66, 161, 0.4)";
        } else {
          ctx.strokeStyle = "rgba(96, 217, 55, 0.4)";
        }

        ctx.strokeRect(x1, y1, w, h);

        if (this.labels) {
          ctx.fillStyle = "rgba(29, 107, 118,0.9)";
          var bodyPartsText;

          var formattedBodyLabel =
            label.charAt(0).toUpperCase() + label.slice(1);
          if (annotationsSource.conf) {
            let conf = (annotationsSource.conf * 100).toFixed(0);
            if (label == "tag" && annotationsSource.tag_id) {
              bodyPartsText = formattedBodyLabel + " " + conf + "%";
              if (annotationsSource.tag_id_conf) {
                bodyPartsText +=
                  " : " +
                  annotationsSource.tag_id +
                  " " +
                  (annotationsSource.tag_id_conf * 100).toFixed(0) +
                  "%";
              } else {
                bodyPartsText += " : " + annotationsSource.tag_id;
              }
            } else {
              bodyPartsText = formattedBodyLabel + ": " + conf + "%";
            }
          } else {
            bodyPartsText = formattedBodyLabel;
          }

          ctx.font = "bold 70% Arial";
          var textWidth = ctx.measureText(bodyPartsText).width;
          var textMetrics = ctx.measureText(bodyPartsText);
          var textHeight =
            textMetrics.actualBoundingBoxAscent +
            textMetrics.actualBoundingBoxDescent;

          ctx.fillRect(x1, y1 - textHeight - 5, textWidth + 3, textHeight + 3);
          ctx.fillStyle = "white";
          ctx.fillText(bodyPartsText, x1 + 2, y1 - 5);
        }
      }
    },
    drawLines(ctx) {
      for (let i = 0; i < this.list_of_lines.length; i++) {
        var x1 = this.scaleX(this.list_of_lines[i].x1);
        var y1 = this.scaleY(this.list_of_lines[i].y1);
        var x2 = this.scaleX(this.list_of_lines[i].x2);
        var y2 = this.scaleY(this.list_of_lines[i].y2);
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgb" + this.list_of_lines[i].linecolor;
        ctx.stroke();
      }
    },
    drawKeypoints(ctx) {
      for (let i = 0; i < this.list_of_keypoints.length; i++) {
        var x1 = this.scaleX(this.list_of_keypoints[i].x1);
        var y1 = this.scaleY(this.list_of_keypoints[i].y1);
        ctx.strokeStyle = "rgb" + this.list_of_keypoints[i].color2;
        ctx.lineWidth = 4;
        ctx.beginPath();
        ctx.arc(x1, y1, 3, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fillStyle = "rgb" + this.list_of_keypoints[i].color1;
        ctx.fill();
      }
    },
    registerJSONElement() {},
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
  },
  created() {
    let temp_local_host_params = JSON.parse(
      localStorage.getItem("image_view_pref")
    );
    if (temp_local_host_params) {
      if (temp_local_host_params.body) {
        this.showbos = true;
      }
      if (temp_local_host_params.bodyparts) {
        this.bodyparts = true;
      }
      if (temp_local_host_params.keypoints) {
        this.keypoints = true;
      }
      if (temp_local_host_params.labels) {
        this.labels = true;
      }
      if (temp_local_host_params.inference_labels) {
        this.inference_labels = true;
      }
    }
    this.isbbox_fill = this.bbox_fill;
    this.bbox_fill = false;
    this.bbox_fill = this.isbbox_fill;

    this.init_load_ready = true;
    this.update_path();
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    const panZoomContainer = document.querySelector(".panZoom-container");
    this.panzoom = Panzoom(panZoomContainer, {
      contain: "outside",
    });
    panZoomContainer.addEventListener("wheel", this.panzoom.zoomWithWheel);
  },
};
</script>

<style>
.main-container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  height: 535px;
}

.custom-style {
  display: flex;
  flex-direction: column;
  height: 535px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.custom-style::-webkit-scrollbar {
  width: 2px;
}

.custom-style::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-style:hover {
  scrollbar-color: lightgrey transparent;
}

.custom-style:hover::-webkit-scrollbar-thumb {
  background-color: lightgrey;
}

.inference-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px; /* Adjust padding to be closer to the text */
  text-transform: uppercase;
  color: #0090a4;
  font-size: small;
  font-weight: bold;
}
.middle {
  display: flex;
  justify-content: center;
  align-content: center;
}

.container-vp {
  padding: 0;
  margin: 0;
}

.video-container {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  position: relative;
  align-items: center;
  height: 535px !important;
}

.panZoom-container {
  width: 100%;
  height: 100%;
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
/* .toggle-switches-events {
  margin-left: 5%;
  margin-top: 3%;
} */

.toggle-switches {
  margin-top: -4%;
  margin-left: 10%;
  width: 80%;
}

.toggle-switches-events {
  margin-left: 10%;
  margin-top: -2%;
}

.toggle-switches-events-inference {
  margin-left: 5%;
  margin-top: 5%;
}
.title-spacing {
  margin-top: -10%;
  padding: 5px !important;
}

.title-spacing-calving {
  /* margin-top: -5%; */
  padding: 5px !important;
}


.toggle-switches-events-visuals {
  margin-left: 5%;
  margin-top: 3%;
}

.label-container {
  margin-top: -10%;
}


.chip-style {
  color: black;
  font-weight: 500;
  margin-bottom: -3px;
}

.image_selector_icons_ac {
  height: 14px;
  display: inline-block;
}
.image_selector_ac {
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 10px;
}
</style>
